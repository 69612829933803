//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { PlanPermissions } from '@/modules/plan/plan-permissions';
import { i18n, getLanguageCode } from '@/i18n';
import moment from 'moment';

import { PlanModel } from '@/modules/plan/plan-model';
import PlanViewModal from '@/modules/plan/components/plan-view-modal.vue';
import TripViewModal from '@/modules/trip/components/trip-view-modal.vue';


// import { FormSchema } from '@/shared/form/form-schema';
const { fields } = PlanModel;

export default {
  name: 'app-active-trip-card',
  props: {
    value: {
      type: Object,
      required: true,
    } 
  },

  components: {
    [PlanViewModal.name]: PlanViewModal,
    [TripViewModal.name]: TripViewModal
  },
  data() {
    return {
      language: getLanguageCode(),
      selectedID: null,
      dialogVisible: false,
      modalVisible: false,
      statusLoading: false,
      
      model: null,
      places: [],
      placesName: [],
      images: [],
      isDisablePlan: false,
    }
  },
  computed: {
    ...mapGetters({
      // record: 'plan/view/record',
      // loading: 'plan/view/loading',
      destroyLoading: 'plan/destroy/loading',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      changeStatusLoading: 'plan/form/changeStatusLoading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    hasPermissionToEdit() {
      return new PlanPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new PlanPermissions(this.currentUser).destroy;
    },
    displayName(){
        return this.value.name ? this.value.name[this.currentLanguageCode] : 'ــــ'
    },
    displayDate(){
        return moment(this.value.createdAt).locale(this.currentLanguageCode).format("D MMM, YYYY"); 
    },
    displayPlaces() {
      return this.placesName.slice(0, 3);
    },
    displayImages() {
      return this.images.slice(0, 3);
    },
    displayRecord() {
      return {
        ...this.value,
        places: this.places,
        images: this.images,
      }
    },
    startPoint() {
      return this.value.startCity ? this.value.startCity.name[this.currentLanguageCode] : 'ــــ'
    },
    endPoint() {
      const lastPlaceIndex = this.placesName.length - 1
      const lastPlace = this.placesName[lastPlaceIndex]
      return lastPlace ? lastPlace[this.currentLanguageCode] : 'ــــ'
    },
  },

  mounted() {
    this.value.programs.forEach(program => {
      program.places.forEach(place => {
        if (place.selectedImage) this.images.push(place.selectedImage)
        if (place.place) this.placesName.push(place.place.placeName)
        if (place.place) this.places.push({ ...place.place, averageTime: place.averageTime })
      })
    });
  },
  methods: {
    ...mapActions({
      doFind: 'plan/view/doFind',
      doDestroy: 'plan/destroy/doDestroy',
      doDisable: 'plan/form/doDisable',
      doEnable: 'plan/form/doEnable',
    }),
    i18n(code, args) {
      return i18n(code, args);
    },
    presenter(row, fieldName) {
      return PlanModel.presenter(row, fieldName);
    },
    presenterMap(row, fieldName) {
      const val = PlanModel.presenter(row, fieldName);
      return val[this.currentLanguageCode]
    },
    presenterDate(row, fieldName) {
      return moment(PlanModel.presenter(row, fieldName)).locale(this.currentLanguageCode).format("D MMM, YYYY"); 
    },
    presenterDay(row, fieldName) {
      return moment(PlanModel.presenter(row, fieldName)).locale(this.currentLanguageCode).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = PlanModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.currentLanguageCode).format("hh:mm a"); 
    },
    onOpenModal() {
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 200);
    },
    // async doDestroyWithConfirm(id) {
    //   try {
    //     await this.doDestroy(id);
    //     this.onModalClose();
    //   } catch (error) {
    //     // no
    //   }
    // },
    // async doDisablePlan() {
    //   this.statusLoading = true
    //   const id = this.value.id
    //   await this.doDisable(id)
    //   this.value.disabled = true
    //   this.statusLoading = false
    // },
    // async doEnablePlan() {
    //   this.statusLoading = true
    //   const id = this.value.id
    //   await this.doEnable(id)
    //   this.value.disabled = false
    //   this.statusLoading = false
    // },
  },
};
