//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { BranchPermissions } from '@/modules/branch/branch-permissions';
// import BranchCard from '@/modules/branch/components/branch-card.vue';
import ActiveTripCard from '@/modules/active-trip/components/active-trip-card.vue';
import BranchFormModal from '@/modules/branch/components/branch-form-modal.vue';
import { i18n } from '@/i18n';

export default {
  name: 'app-active-trip-list-card',
  props: {
    values: {
      type: Array,
      required: true,
    } 
  },

  components: {
    // [BranchCard.name]: BranchCard,
    [ActiveTripCard.name]: ActiveTripCard,
    [BranchFormModal.name]: BranchFormModal,
  },

  data() {
    return {
      current: 3,
      modalVisible: false,
      dialogVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',

      // rows: 'iam/list/rows',
      // count: 'iam/list/count',
      // loading: 'iam/list/loading',
      // pagination: 'iam/list/pagination',
    }),
    hasPermissionToCreate() {
      return new BranchPermissions(this.currentUser).create;
    },
  },
  methods: {
    ...mapActions({
      // doChangeSort: 'iam/list/doChangeSort',
      // doChangePaginationCurrentPage: 'iam/list/doChangePaginationCurrentPage',
      // doChangePaginationPageSize: 'iam/list/doChangePaginationPageSize',
      // doMountTable: 'iam/list/doMountTable',
    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    onOpenModal() {
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 200);
    },
  },
};
