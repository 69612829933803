//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { BranchPermissions } from '@/modules/branch/branch-permissions';
import BranchListCard from '@/modules/branch/components/branch-list-card.vue';
import ActiveTripListCard from '@/modules/active-trip/components/active-trip-list-card.vue';

import { i18n } from '@/i18n';

export default {
  name: 'app-branch-list-page',

  components: {
    [BranchListCard.name]: BranchListCard,
    [ActiveTripListCard.name]: ActiveTripListCard
  },

  data() {
    return {
      activeTrips: [
        {
          id: '3C4wt2RfNCjNOM0UjiuC',
          name: {
            en: 'Makkah Trip',
            ar: 'خطة أ ',
          },
          description: {
            en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus  venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget .',
            ar: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus  venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget .',
          },
          startCityId: '',
          type: 'custom',
          numberOfDays: 3,
          disabled: false,
          startCity: {
            id: '',
            name: {
              en: 'Quba Mosque',
              ar: 'مسجد قباء',
            }
          },
          programs: [
            {
              name: '1',
              places: [
                {
                  place: {
                    id: '',
                    placeName: {
                      en: 'Quba Mosque',
                      ar: 'مسجد قباء',
                    },
                    placePictures: {
                      id: '',
                      name: '',
                      publicUrl: '',
                      privateUrl: '',
                    },
                    country: {
                      en: 'Egypt',
                      ar: 'مصر',
                    },
                    city: {
                      id: '1',
                      name: {
                        en: 'Alexandria',
                        ar: 'الاسكندرية',
                      }
                    }
                  },
                  id: 'place1',
                  selectedImage: '/images/test/place1.webp',
                  averageTime: 15,
                },
                {
                  place: {
                    id: '',
                    placeName: {
                      en: 'Quba Mosque',
                      ar: 'مسجد قباء',
                    },
                    placePictures: {
                      id: '',
                      name: '',
                      publicUrl: '',
                      privateUrl: '',
                    },
                    country: {
                      en: 'Egypt',
                      ar: 'مصر',
                    },
                    city: {
                      id: '1',
                      name: {
                        en: 'Alexandria',
                        ar: 'الاسكندرية',
                      }
                    }
                  },
                  id: 'place1',
                  selectedImage: '/images/test/place3.webp',
                  averageTime: 15,
                },
                {
                  place: {
                    id: '',
                    placeName: {
                      en: 'Quba Mosque',
                      ar: 'مسجد قباء',
                    },
                    placePictures: {
                      id: '',
                      name: '',
                      publicUrl: '',
                      privateUrl: '',
                    },
                    country: {
                      en: 'Egypt',
                      ar: 'مصر',
                    },
                    city: {
                      id: '1',
                      name: {
                        en: 'Alexandria',
                        ar: 'الاسكندرية',
                      }
                    }
                  },
                  id: 'place1',
                  selectedImage: '/images/test/place2.webp',
                  averageTime: 15,
                },
                {
                  place: {
                    id: '',
                    placeName: {
                      en: 'Quba Mosque',
                      ar: 'مسجد قباء',
                    },
                    placePictures: {
                      id: '',
                      name: '',
                      publicUrl: '',
                      privateUrl: '',
                    },
                    country: {
                      en: 'Egypt',
                      ar: 'مصر',
                    },
                    city: {
                      id: '1',
                      name: {
                        en: 'Alexandria',
                        ar: 'الاسكندرية',
                      }
                    }
                  },
                  id: 'place1',
                  selectedImage: '/images/test/place1.webp',
                  averageTime: 15,
                },
                {
                  place: {
                    id: '',
                    placeName: {
                      en: 'Quba Mosque',
                      ar: 'مسجد قباء',
                    },
                    placePictures: {
                      id: '',
                      name: '',
                      publicUrl: '',
                      privateUrl: '',
                    },
                    country: {
                      en: 'Egypt',
                      ar: 'مصر',
                    },
                    city: {
                      id: '1',
                      name: {
                        en: 'Alexandria',
                        ar: 'الاسكندرية',
                      }
                    }
                  },
                  id: 'place1',
                  selectedImage: '/images/test/place3.webp',
                  averageTime: 15,
                },
                {
                  place: {
                    id: '',
                    placeName: {
                      en: 'Quba Mosque',
                      ar: 'مسجد قباء',
                    },
                    placePictures: {
                      id: '',
                      name: '',
                      publicUrl: '',
                      privateUrl: '',
                    },
                    country: {
                      en: 'Egypt',
                      ar: 'مصر',
                    },
                    city: {
                      id: '1',
                      name: {
                        en: 'Alexandria',
                        ar: 'الاسكندرية',
                      }
                    }
                  },
                  id: 'place1',
                  selectedImage: '/images/test/place2.webp',
                  averageTime: 15,
                },
              ],
            },
            {
              name: '2',
              places: [
                {
                  place: {
                    id: '',
                    placeName: {
                      en: 'Quba Mosque',
                      ar: 'مسجد قباء',
                    },
                    placePictures: {
                      id: '',
                      name: '',
                      publicUrl: '',
                      privateUrl: '',
                    },
                    country: {
                      en: 'Egypt',
                      ar: 'مصر',
                    },
                    city: {
                      id: '1',
                      name: {
                        en: 'Alexandria',
                        ar: 'الاسكندرية',
                      }
                    }
                  },
                  id: 'place2',
                  selectedImage: '/images/test/place2.webp',
                  averageTime: 30,
                },
              ],
            },
            {
              name: '3',
              places: [
                {
                  place: {
                    id: '',
                    placeName: {
                      en: 'Quba Mosque',
                      ar: 'مسجد قباء',
                    },
                    placePictures: {
                      id: '',
                      name: '',
                      publicUrl: '',
                      privateUrl: '',
                    },
                    country: {
                      en: 'Egypt',
                      ar: 'مصر',
                    },
                    city: {
                      id: '1',
                      name: {
                        en: 'Alexandria',
                        ar: 'الاسكندرية',
                      }
                    }
                  },
                  id: 'place3',
                  selectedImage: '/images/test/place3.webp',
                  averageTime: 20,
                },
              ],
            },
          ],
      
          createdBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
          updatedBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
          createdAt: '2021-02-08T20:59:08.103Z',
          updatedAt: '2021-02-08T20:59:08.103Z',
        },
        {
          id: '3C4wt2RfNCjNOM0UjiuC',
          name: {
            en: 'Makkah Trip',
            ar: 'خطة ب ',
          },
          description: {
            en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus  venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget .',
            ar: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus  venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget .',
          },
          startCityId: '',
          type: 'custom',
          numberOfDays: 3,
          disabled: false,
          startCity: {
            id: '',
            name: {
              en: 'Quba Mosque',
              ar: 'مسجد قباء',
            }
          },
          programs: [
            {
              name: '1',
              places: [
                {
                  place: {
                    id: '',
                    placeName: {
                      en: 'Quba Mosque',
                      ar: 'مسجد قباء',
                    },
                    placePictures: {
                      id: '',
                      name: '',
                      publicUrl: '',
                      privateUrl: '',
                    },
                    country: {
                      en: 'Egypt',
                      ar: 'مصر',
                    },
                    city: {
                      id: '1',
                      name: {
                        en: 'Alexandria',
                        ar: 'الاسكندرية',
                      }
                    }
                  },
                  id: 'place1',
                  selectedImage: '/images/test/place1.webp',
                  averageTime: 15,
                },
              ],
            },
            {
              name: '2',
              places: [
                {
                  place: {
                    id: '',
                    placeName: {
                      en: 'Quba Mosque',
                      ar: 'مسجد قباء',
                    },
                    placePictures: {
                      id: '',
                      name: '',
                      publicUrl: '',
                      privateUrl: '',
                    },
                    country: {
                      en: 'Egypt',
                      ar: 'مصر',
                    },
                    city: {
                      id: '1',
                      name: {
                        en: 'Alexandria',
                        ar: 'الاسكندرية',
                      }
                    }
                  },
                  id: 'place2',
                  selectedImage: '/images/test/place2.webp',
                  averageTime: 30,
                },
              ],
            },
            {
              name: '3',
              places: [
                {
                  place: {
                    id: '',
                    placeName: {
                      en: 'Quba Mosque',
                      ar: 'مسجد قباء',
                    },
                    placePictures: {
                      id: '',
                      name: '',
                      publicUrl: '',
                      privateUrl: '',
                    },
                    country: {
                      en: 'Egypt',
                      ar: 'مصر',
                    },
                    city: {
                      id: '1',
                      name: {
                        en: 'Alexandria',
                        ar: 'الاسكندرية',
                      }
                    }
                  },
                  id: 'place3',
                  selectedImage: '/images/test/place3.webp',
                  averageTime: 20,
                },
              ],
            },
          ],
      
          createdBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
          updatedBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
          createdAt: '2021-02-08T20:59:08.103Z',
          updatedAt: '2021-02-08T20:59:08.103Z',
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    hasPermissionToCreate() {
      return new BranchPermissions(this.currentUser).create;
    },
  },
  methods: {
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
  },
};
