//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/i18n';
import { getLanguageCode } from '@/i18n';

import { PlanModel } from '@/modules/plan/plan-model';
const { fields } = PlanModel;

export default {
  name: 'app-plan-view-modal',
  props: ['visible', 'record'],
  
  data() {
    return {
      language: getLanguageCode(),
      model: {},
      citiesOptions: [],
      searchField: '',
      slides: 7,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'plan/list/loading',
      filter: 'plan/list/filter',
      is_screen_xs: 'layout/is_screen_xs',
      cities: 'city/list/rows',
      citiesLoading: 'city/list/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    fields() {
      return fields;
    },
    
  },

  methods: {
    ...mapActions({
      doReset: 'plan/list/doReset',
      // doFetch: 'plan/list/doFetchGroupbyCity',
      // doFetchCities: 'city/list/doFetch',
    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    doClose() {
      this.$emit('close')
    },
    displayStayTime(time) {
      let text = ''
      let hour = 0
      let mins = 0

      if (time < 60) {
        mins = time
        text = this.i18n('entities.plan.view.visitorStayMins', [mins])
      } else if (time > 60) {
        hour = Math.floor(time / 60)
        mins = time % 60;
        text = this.i18n('entities.plan.view.visitorStayHourMins', [hour, mins])
      } else {
        text = this.i18n('entities.plan.view.visitorStayHour', 1)
      }

      return text
    },
  },
};
